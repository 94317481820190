@import "../../../../styles/shared.scss";
.modal-onboarding {
  &.modal {
    .modal-body {
      display: flex;
      flex-direction: column;
    }
    .slider-container {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }
    h1 {
      margin: 0;
      padding: 0 50px;
      font-size: 32px;
      line-height: 38px;
      @include media-breakpoint-down(xs) {
        font-size: 21px;
        line-height: 25px;
        padding: 0 20px;
      }
    }
    h5 {
      padding: 0 50px;
      @include media-breakpoint-down(xs) {
        padding: 0 20px;
      }
    }
    .figure-description {
      padding: 0 2.5rem;
      // background: $miggsterRed;
    }
    .skip-btn {
      color: $white;
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover {
        opacity: 0.6;
      }
      &:active {
        opacity: 0.3;
        transition: all 0.2s ease;
      }
    }
    .swiper-wrapper {
      padding-top: 0px;
      align-items: center;
    }
    .onboarding-image {
      width: 250px;
      height: 250px;
      margin: 0;
      &.profile-pic-200 {
        width: 220px;
        height: 220px;
        margin: 20px 0;
        border: 10px solid rgba(lighten($miggsterRed, 5%), 0.3);
        box-shadow: 0px 0px 100px rgba($miggsterRed, 0.8);
        animation: glow 5s ease 0s infinite forwards;
        @include media-breakpoint-down(xs) {
          width: 180px;
          height: 180px;
        }
      }
    }
    .modal-next-container {
      padding: 1.5rem 2.5rem 2rem 2.5rem;
      text-align: center;
      .next-btn {
        background: $miggsterRed;
        outline: none;
        text-align: center;
        border-radius: 6px;
        padding: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        max-width: 150px;
        margin: 0 auto 15px auto;
        transition: all 0.5s ease;
        &:hover {
          background: lighten($miggsterRed, 15%);
        }
        &:active {
          transition: all 0.2s ease;
          background: darken($miggsterRed, 15%);
        }
      }
    }
    .modal-footer {
      margin-bottom: 20px;
      .prev-btn,
      .next-btn {
        &:focus,
        &:active {
          outline: none;
        }
      }
      .prev-btn {
        font-size: 16px;
        color: $bluish-grey;
        cursor: pointer;
        &.swiper-button-disabled {
          display: none;
        }
      }

      .next-btn {
        font-weight: bold;
        font-size: 16px;
        color: $secondary;
        cursor: pointer;
      }

      .onboarding-pagination {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 5%;
          z-index: 1;
          width: 90%;
          height: 1px;
          background: rgba($white, 0.1);
        }
        @include media-breakpoint-down(xs) {
          height: 50px;
        }
        span {
          &.swiper-pagination-bullet {
            width: 50px !important;
            height: 50px !important;
            display: flex;
            margin: 0 30px 0 0 !important;
            padding: 5px;
            position: relative;
            z-index: 2;
            opacity: 1;
            background: none;
            outline: none !important;
            &:last-of-type {
              margin-right: 0 !important;
            }
            @include media-breakpoint-down(xs) {
              margin-right: 15px !important;
              width: 40px !important;
              height: 40px !important;
            }
            .inner-bullet {
              width: 40px;
              height: 40px;
              padding-top: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              font-size: 20px;
              line-height: 20px;
              text-align: center;
              background: $miggsterGrey;
              color: rgba($white, 0.1);
              border: 2px solid rgba($white, 0.1);
              transition: border 0.2s ease, color 0.5s ease;
              transform-origin: center;
              @include media-breakpoint-down(xs) {
                width: 30px !important;
                height: 30px !important;
                font-size: 16px;
                line-height: 16px;
              }
            }
            
            &.swiper-pagination-bullet-active {
              // background: darken($miggsterRed, 15%) !important;
              background: transparent !important;
              box-shadow: 0 2px 10px rgba($black, 0.5);
              .inner-bullet {
                color: $white;
                background: $miggsterRed;
                border-color: transparent;
              }
            }
          }
        }
      }
      &.mtn{
        .next-btn {
          color: $miggsterRed;
          transition: opacity 0.5s ease;
          &:hover {
            opacity: 0.7;
          }
          &:active {
            opacity: 0.25;
          }
        }
      }
      &.is-slide-2 {
        .onboarding-pagination {
          span {
            &.swiper-pagination-bullet {
              &:nth-child(1) {
                .inner-bullet {
                  color: transparent;
                  background-image: url(../../../../assets/images/svg/red-tick.svg);
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 20px 20px;
                  @include media-breakpoint-down(xs) {
                    background-size: 16px 16px;
                  }
                }
              }
            }
          }
        }
      }
      &.is-slide-3 {
        .onboarding-pagination {
          span {
            &.swiper-pagination-bullet {
              &:nth-child(1),
              &:nth-child(2) {
                .inner-bullet {
                  color: transparent;
                  background-image: url(../../../../assets/images/svg/red-tick.svg);
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 20px 20px;
                  @include media-breakpoint-down(xs) {
                    background-size: 16px 16px;
                  }
                }
              }
            }
          }
        }
      }
      &.is-slide-4 {
        .onboarding-pagination {
          span {
            &.swiper-pagination-bullet {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3) {
                .inner-bullet {
                  color: transparent;
                  background-image: url(../../../../assets/images/svg/red-tick.svg);
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 20px 20px;
                  @include media-breakpoint-down(xs) {
                    background-size: 16px 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .popup-dismiss {
      position: absolute;
      right: 25px;
      cursor: pointer;
      top: 25px;
      transition: all 0.5s ease;
      &:hover {
        opacity: 0.6;
      }
      &:active {
        opacity: 0.3;
        transition: all 0.2s ease;
      }
    }
    .swiper-slide {
      height: 92%;
    }
  }
}
.tutorial {
  &.swiper-slide {
    width: 100% !important;
  }
}

@keyframes glow {
  0% {
    border: 10px solid rgba(lighten($miggsterRed, 5%), 0.3);
    box-shadow: 0px 0px 100px rgba($miggsterRed, 0.8);
  }
  50% {
    border: 10px solid rgba(lighten($miggsterRed, 30%), 0.3);
    box-shadow: 0px 0px 60px rgba(darken($miggsterRed, 10%), 0.4);
  }
  100% {
    border: 10px solid rgba(lighten($miggsterRed, 5%), 0.3);
    box-shadow: 0px 0px 100px rgba($miggsterRed, 0.8);
  }
}