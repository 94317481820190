@import "../../../styles/shared.scss";
.modalV2 {
  .create-player {
    .input-group-wrapper {
      position: relative;
      .checked-playername {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    form {
      .btn {
        color: $white;
        background: $miggsterRed;
        outline: none;
        text-align: center;
        border-radius: 6px;
        padding: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        max-width: 150px;
        margin: 0 auto;
        transition: all 0.5s ease;
        border: none !important;
        cursor: pointer;
        &:hover {
          background: lighten($miggsterRed, 15%);
        }
        &:active {
          transition: all 0.2s ease;
          background: darken($miggsterRed, 15%);
        }
      }
    }

    // .avatar {
    //   position: relative;
    //   .avatar-wrapper {
    //     width: 200px;
    //     height: 200px;
    //     img {
    //       max-width: 200px;
    //       max-height: 200px;
    //       border-radius: 50%;
    //     }
    //     .upload-button {
    //       position: absolute;
    //       bottom: 0;
    //       left: 0;
    //       right: 0;
    //       background: rgba(255, 255, 255, 0.85);
    //       padding-top: 5px;
    //       padding-bottom: 35px;
    //       width: 200px;
    //       margin: 0 auto;

    //       img {
    //         width: 30px;
    //         height: 30px;
    //         margin-bottom: 5px;
    //       }

    //       .choose-photo {
    //         font-size: 16px;
    //         font-weight: bold;
    //         font-style: normal;
    //         font-stretch: normal;
    //         line-height: 1.38;
    //         letter-spacing: normal;
    //         text-align: center;
    //         color: $secondary;
    //       }
    //     }
    //   }
    //   .file {
    //     visibility: hidden;
    //     position: absolute;
    //   }
    // }
    .bottom-margin {
      margin-bottom: 80px;
    }
  }
  .iphone-SE-top {
    &.mt {
      margin-top: 2rem !important;
    }
  }

  .mainLogo {
    height: 60%;
    width: auto;
  }
}
