@import "./shared.scss";
@import "./bootstrap";
@import "button";
@import "form";
@import "cards";
@import "sidebar";
@import "navigation";
@import "footer";
@import "profile-pic";
@import "typography";
@import "./tab-bar.scss";
@import "datepicker";
@import url("https://fonts.googleapis.com/css?family=Exo:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
// Eurofy commented out
// @import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

html,
body,
#root {
	height: 100%;
	width: 100%;
	font-size: 14px;
	line-height: 1.5;
	color: $default;
	font-family: $font-family-base;
	font-weight: normal;
	background-color: $miggsterBGDarkGrey;
}

// Eurofy commented out
// h1, h2 {
//   font-size: 22px !important;
//   font-family: 'Press Start 2P', cursive;
//   // @include media-breakpoint-down(lg) {
//   //   font-size: 21px;
//   // }
//   // @include media-breakpoint-down(md) {
//   //   font-size: 20px;
//   // }
//   // @include media-breakpoint-down(sm) {
//   //   font-size: 19px;
//   // }
//   @include media-breakpoint-down(xs) {
//     font-size: 16px !important;
//   }
// }

.loader-mtn {
	margin: 30px;
	width: 280px;
	height: auto;
	@include media-breakpoint-down(md) {
		width: 200px;
		margin-left: 46px;
	}
}
a,
a:hover,
a:focus,
.a,
.a:hover,
.a:focus {
	color: $default;
	text-decoration: none;
	cursor: pointer;
}
.bold {
	font-weight: bold;
}

.event-section {
	// Retrofy commented out
	// margin-top: -100px;
	// @include media-breakpoint-down(md) {
	//   margin-top: -75px;
	// }
	// @include media-breakpoint-down(sm) {
	//   margin-top: 0;
	// }
	// Retrofy added in
	margin-top: 0;
}

.padder {
	padding: 8rem 0rem;
	@include media-breakpoint-down(sm) {
		padding: 6rem 0rem;
	}
}
.padder-main {
	padding: 5rem 0rem;
	@include media-breakpoint-down(lg) {
		padding: 7rem 0rem;
	}
	@include media-breakpoint-down(md) {
		padding: 3rem 0rem;
	}
	@include media-breakpoint-down(xs) {
		padding: 2.5rem 0;
	}
}
.padder-bottom {
	padding: 0 0 5rem 0;
	@include media-breakpoint-down(lg) {
		padding: 0 0 7rem 0;
	}
	@include media-breakpoint-down(md) {
		padding: 0 0 3rem 0;
	}
}
.padder-top {
	padding-top: 4rem;
	@include media-breakpoint-down(lg) {
		padding-top: 4rem;
	}
	@include media-breakpoint-down(md) {
		padding-top: 5rem;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 3rem;
	}
	@include media-breakpoint-down(xs) {
		padding-top: 2.5rem;
	}
}
.mb-5-half {
	margin-bottom: 2.5rem;
	@include media-breakpoint-down(sm) {
		margin-bottom: 1.5rem;
	}
}
.uppercase {
	text-transform: uppercase;
}
figure {
	margin-bottom: 0px;

	figcaption {
		margin-bottom: 0px;
	}
}
.fixed-bottom {
	z-index: 1025;
}
.mb-30 {
	margin-bottom: 30px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 15px;
	}
}
.text-mtn-light-black {
	color: $mtn-light-black;
}
.text-gray {
	color: $gray;
}
.text-yellow {
	color: $yellow;
}
.bold {
	font-weight: bold;
}
.semi-bold {
	font-weight: 500;
}
.section-border-top {
	border-top: 1px solid rgba($pale-blue, 0.5);
}
.mx-15 {
	margin-left: 15px;
	margin-right: 15px;
}
.fs-12 {
	font-size: 12px;
}
.bb-1 {
	box-shadow: inset 0 -1px 0 0 $pale-blue;
}
.bt-1 {
	border-top: 1px solid $pale-blue;
}
.mt-72 {
	margin-top: 72px;
}

.bold {
	font-weight: bold;
}

.section-border-top {
	border-top: 1px solid rgba($pale-blue, 0.5);
}
.profile-pic-120 {
	width: 120px;
	height: 120px;
	border-radius: 50%;
}
.profile-pic-80 {
	width: 80px;
	height: 80px;
	border-radius: 50%;
}
.profile-pic-48 {
	width: 48px;
	height: 48px;
	border-radius: 50%;
}
// .go-premium {
//   position: fixed;
//   top: 0;
//   width: 100%;
//   height: 20px;
//   z-index: 100000;
//   background-color: lighten($secondary, 20%);
// }

// added for banner in news, contact, terms & conditions, privacy policy
.page-header {
	width: 100%;
	height: 60vh;
	background-color: $miggsterRed;
	// background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: relative;
	display: flex;
	align-items: center;

	&::after {
		content: "";
		width: 100%;
		height: 70%;
		background-image: linear-gradient(
			to bottom,
			rgba(32, 32, 41, 0),
			rgba(32, 32, 41, 1)
		);
		position: absolute;
		bottom: 0;
		z-index: 0;
	}
	.container {
		z-index: 1;
		h1:only-child {
			margin-bottom: 0 !important;
		}
	}
	@include media-breakpoint-down(md) {
		height: 400px;
	}
	@include media-breakpoint-down(sm) {
		height: 300px;
	}
	@include media-breakpoint-down(xs) {
		height: 200px;
	}
}

.banner {
	&.news {
		// margin-top: 72px;
		// margin-left: 3vw;
		svg {
			overflow: hidden;
		}
		background-image: none;
		width: 100%;
		height: 100%;
	}
	@include media-breakpoint-down(lg) {
		&.news {
			margin-left: 0px;
			margin-bottom: 30px;
		}
	}
	// @media screen and (min-width: 900px) {
	//   &.news {
	//     margin-left: 0px;
	//     margin-bottom: 83px;
	//   }
	// }
}
.help-support {
	.contents {
		position: absolute;
		width: 60%;
		height: 60%;
		h2 {
			left: -25px;
			position: relative;
		}
		h5 {
			left: -18px;
			position: relative;
		}
	}
	@include media-breakpoint-down(lg) {
		//margin-top: -80px;
		.contents {
			h2 {
				left: 35px;
				font-size: 32px;
			}
			h5 {
				left: 38px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		background-color: $lightish-blue;
		&.mtn {
			background-color: $mtnThemeMainColor;
		}
		margin-left: 0px;
		margin-top: 0px;
		svg {
			display: none;
		}
		.contents {
			position: relative;
			width: 100%;
			height: 100%;
			margin-left: 0px;
			padding: 20px 0px;
			h2 {
				font-size: 32px;
				left: -15px;
				position: relative;
			}
			h5 {
				left: -15px;
				position: relative;
			}
		}
		.mt-6 {
			margin-top: 2rem;
		}
		.mb-6 {
			margin-bottom: 2rem;
		}
	}
	@include media-breakpoint-down(sm) {
		.contents {
			h2 {
				position: relative;
			}
			h5 {
				position: relative;
			}
		}
	}
}
.load-bar {
	position: fixed;
	top: 0;
	width: 100%;
	height: 4px;
	z-index: 100000;
	background-color: lighten($secondary, 20%);

	.bar {
		content: "";
		display: inline;
		position: absolute;
		width: 0;
		height: 100%;
		left: 50%;
		text-align: center;
		&:nth-child(1) {
			animation: loading 2.1s linear 0.7s infinite;
			background-color: $secondary;
		}
		&:nth-child(2) {
			animation: loading 2.1s linear 1.4s infinite;
			background-color: lighten($secondary, 20%);
		}
	}
	&.orange {
		background-color: lighten($orange, 20%);
		.bar {
			&:nth-child(1) {
				background-color: $orange;
			}
			&:nth-child(2) {
				background-color: lighten($orange, 20%);
			}
		}
	}
	&.mtnBlack {
		height: 3px;
		background-color: lighten($miggsterRed, 20%);
		.bar {
			&:nth-child(1) {
				background-color: $miggsterRed;
			}
			&:nth-child(2) {
				background-color: lighten($miggsterRed, 20%);
			}
		}
	}
}

@keyframes loading {
	from {
		left: 50%;
		width: 0;
		z-index: 100;
	}
	33.3333% {
		left: 0;
		width: 100%;
		z-index: 10;
	}
	to {
		left: 0;
		width: 100%;
	}
}
.ui-blocker {
	background: transparent;
	width: 100%;
	height: 100%;
	z-index: 5000;
	position: fixed;
	top: 72px;
	left: 0px;
}

.view-all {
	font-weight: 500;
	font-size: 16px;
	color: $secondary;
	cursor: pointer;
	&:hover,
	&:focus {
		color: $secondary !important  ;
	}
	@include media-breakpoint-down(sm) {
		font-size: 12px;
	}
}
.view-all.mtn {
	// color: $white;
	color: hotpink;
	opacity: 0.3;
	transition: all 0.25s ease-out;
	span {
		margin: -1px 0 0 5px;
		width: 13px;
		height: 13px;
		display: block;
		img {
			width: 100%;
			display: block;
			transition: all 0.25s ease-out;
		}
	}
	&:hover,
	&:focus {
		color: $white !important;
		opacity: 1;
		span {
			width: 13px;
			height: 13px;
			img {
				transform: translateX(3px);
			}
		}
	}
}

.text-link {
	color: $white;
	opacity: 0.6;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	cursor: pointer;
	transition: all 0.25s ease-out;
	@include media-breakpoint-down(sm) {
		font-size: 12px;
		line-height: 12px;
		padding-right: 13px;
		background-size: 10px 10px;
	}
	&:hover,
	&:focus {
		color: $white !important;
		opacity: 1;
	}
}

.more-btn {
	color: $white;
	opacity: 0.6;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	cursor: pointer;
	transition: all 0.25s ease-out;
	padding-right: 18px;
	background: url(../assets/images/svg/nav-arrow-right.svg) no-repeat right
		1px top 1px;
	background-size: 13px 13px;
	@include media-breakpoint-down(sm) {
		font-size: 12px;
		line-height: 12px;
		padding-right: 13px;
		background-size: 10px 10px;
	}
	&:hover,
	&:focus {
		color: $white !important;
		opacity: 1;
		background-position: right -2px top 1px;
	}
}

.back-btn {
	color: $white;
	opacity: 0.3;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	cursor: pointer;
	transition: all 0.25s ease-out;
	padding-left: 16px;
	background: url(../assets/images/svg/nav-arrow-left.svg) no-repeat -1px 1px;
	background-size: 13px 13px;
	@include media-breakpoint-down(sm) {
		font-size: 12px;
		line-height: 12px;
		padding-left: 13px;
		background-size: 10px 10px;
	}
	&:hover,
	&:focus {
		color: $white !important;
		opacity: 1;
		background-position: -4px 1px;
	}
}

.view-all-dashboard {
	font-weight: 500;
	font-size: 16px;
	color: $secondary;
	cursor: pointer;
	&:hover,
	&:focus {
		color: $secondary;
	}
	@include media-breakpoint-down(sm) {
		font-size: 12px;
	}
}
.swiper-container {
	width: 100%;
	margin-top: -5px;
	.swiper-wrapper {
		padding-top: 5px;
		// transition-timing-function: linear;
		.swiper-slide {
			width: auto;
			transition: all 0.3s ease;
			&:last-child {
				margin-right: 0px;
			}
			&:hover {
				transform: translateY(-5px);
			}
			&:active {
				transition: all 0.1s ease;
				transform: scale(0.99);
			}
			@include media-breakpoint-down(sm) {
				&:hover {
					transform: translateY(0);
				}
				&:active {
					transform: scale(1);
				}
			}
		}
	}
}

// Retrofy commented out
// .live-tournament,
// .quick-play:not(.card),
// .premium-games,
// .upcoming-tournament,
// .popular-host,
// .free-to-play-games,
// .prize-pull,
// .news,
// .about-mtn-treasure,
// .enter-raffles-wrapper,
// .active-raffle {
//   background-image: linear-gradient(to top, #121217, #202029);
// }

@supports (-webkit-touch-callout: none) {
	.live-tournament,
  .quick-play:not(.card),
  .premium-games,
  .upcoming-tournament,
  .popular-host,
  .free-to-play-games,
  .prize-pull,
  // .news,
  .about-mtn-treasure,
  .enter-raffles-wrapper,
  .active-raffle {
		background-image: linear-gradient(to top, #121217, #202029);
	}
}

.bg-light-gradient {
	background: rgba(32, 32, 41, 1);
	background: linear-gradient(
		0deg,
		rgba(32, 32, 41, 1) 0%,
		rgba(18, 18, 23, 0) 60%
	);
}

.empty-state-text {
	color: $mtn-light-black;
}

.blank-space {
	height: 72px;
}

.mainSection {
	// Eurofy added in
	background-image: url(../assets/images/Backgrounds/Miggster_play_home_screen_deep_space_new.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center;
	// @include media-breakpoint-down(sm) {
	//   // background-image: url(../assets/images/football-bg-mobile.png) !important;
	//   background-position: bottom center;
	//   -webkit-background-size: 140% auto;
	//   -moz-background-size: 140% auto;
	//   -o-background-size: 140% auto;
	//   background-size: 140% auto;
	// }
	@supports (-webkit-touch-callout: none) {
		// background-position: bottom center;
		// background: rgb(8,14,39);
		// background: linear-gradient(0deg, rgba(8,14,39,1) 0%, rgb(45, 90, 60) 100%);
	}
}

.mainContainer {
	margin-top: 72px;
	opacity: 0;
	transition: opacity 0.5s;
}
.mainContainer.fadein {
	opacity: 1;
}
.loader-container {
	min-width: 100vw;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.loader-container.hide {
	display: none;
}
.loader-container img {
	width: 200px;
	height: 200px;
	@include media-breakpoint-down(sm) {
		width: 140px;
		height: 140px;
		margin-top: 0px;
	}
}
.loader-mtn {
	width: 200px;
	height: 200px;
	@include media-breakpoint-down(sm) {
		width: 140px;
		height: 140px;
	}
}

.games-loader-container {
	margin-top: 100px;
	@include media-breakpoint-down(sm) {
		margin-top: 0px;
	}
}
.games-loader-container img {
	width: 200px;
	height: 200px;
	@include media-breakpoint-down(sm) {
		width: 140px;
		height: 140px;
	}
}
.disable-scroll {
	overflow: hidden;
}
.btn-link {
	background: transparent;
}

.dynamic-banner {
	width: 100%;
	max-height: 500px;
	object-fit: cover;
}
.newBanner img {
	// min-height: 550px;
	background-image: linear-gradient(to top, #121217, #202029);

	// @include media-breakpoint-down(xl) {
	//   min-height: 500px;
	// }
	// @include media-breakpoint-down(lg) {
	//   min-height: 316px;
	// }
	// @include media-breakpoint-down(md) {
	//   min-height: 253px;
	// }
	// @include media-breakpoint-down(sm) {
	//   min-height: 142px;
	// }
	// @include media-breakpoint-down(xs) {
	//   min-height: 85px;
	// }
}
.no-gutters {
	&.no-space {
		margin-right: -15px;
		margin-left: -15px;
	}
}
.rounded-pill {
	border-radius: 50rem !important;
}
.overflow-auto {
	overflow: auto !important;
}
.loading {
	background: rgba(0, 0, 0, 0.1);
	// position: absolute;
	height: 100vh;
	width: 100%;
	// top:72px;
}

.tournamet-info-wrapper {
	padding: 25px 0px;
	h1 {
		color: $miggsterGrey;
	}
	p {
		margin-bottom: 0px;
	}
	background: #ffffff;
	box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24);
	.responsive-adjust {
		.media {
			.timer {
				max-width: 32px;
				max-height: 32px;
			}
			.media-body {
				span {
					font-weight: 500;
				}
			}
		}
		.ms-flex {
			flex: 1;
			-ms-flex: 1;
		}
	}
	.counter-btn-wraper {
		span {
			font-weight: normal;
			font-size: 12px;
		}
	}
	.mt-negative-50 {
		margin-top: -50px;
	}
	.profile-avatar {
		max-width: 140px;
		width: 100%;
		box-shadow: 0 9px 20px -8px rgba(0, 0, 0, 0.3);
		border-radius: 50%;
		@include media-breakpoint-down(md) {
			max-width: 65px;
		}
	}

	@include media-breakpoint-down(md) {
		.media {
			margin-top: 5px;
		}
		.countDown-timer {
			font-size: 11px;
		}
	}

	@include media-breakpoint-down(sm) {
		.title-small-responsive {
			font-size: 18px;
		}
		.countDown-timer {
			font-size: 11px;
		}
		padding: 12px 0px;
		.responsive-adjust {
			[class^="col"] {
				padding-left: 0px;
			}
			a {
				&.btn {
					&.ml-2 {
						margin-left: 0px !important;
					}
				}
			}
			h6 {
				font-size: 16px;
			}
			.media {
				margin-top: 10px;
				.timer {
					max-width: 24px;
					max-height: 24px;
				}
				.media-body {
					h6 {
						font-size: 12px;
					}
				}
			}
		}
	}

	&.host-detail {
		.social-media {
			img {
				width: 7vw;
				max-width: 28px;
				height: 7vw;
				max-height: 28px;
			}
		}
		@include media-breakpoint-down(md) {
			.host-heading {
				margin-top: -15px;
				margin-bottom: 20px;
				h1 {
					font-size: 21px;
				}
			}
			padding: 20px 0px;
		}
	}
}

.my-tournament-wrapper {
	margin-top: 72px;
	// Retrofy commented out
	// background-image: linear-gradient(to top, #121217, #202029);
	// Retrofy added in
	min-height: 100vh;

	.card-wrapper {
		[class^="col-"] {
			padding-right: 10px;
		}

		.card {
			&.card-my-tournament {
				margin-bottom: 20px;
				@include popular-arcadex(
					$CardWidth: 212px,
					$cardHeight: 340px,
					$imgHeight: 170px,
					// Retrofy commented out
					// $card-border-radius: 18px
					// Retrofy added in
					$card-border-radius: 2px
				);
				&.dark {
					@include box-shadow(0px, 3px, 10px, darken($info, 10%));
				}
				&.lock {
					.lock-info {
						img {
							width: 80px;
						}
						h6 {
							font-size: 12px;
						}
						h2 {
							font-size: 21px;
							margin-top: 0.7rem;
						}
						.btn-premium {
							&.unlock {
								width: 82%;
								line-height: 2.5;
							}
						}
					}
				}
				.card-body {
					padding: 0rem 2rem;
					@include media-breakpoint-down(sm) {
						padding: 0rem 1.2rem;
					}
					h4 {
						font-size: 20px;
						line-height: 24px;
						color: $miggsterGrey;
					}
				}
				figure {
					margin-bottom: 7px;
					img {
						width: 56px;
						height: auto;
						margin-left: -10px;
					}
					figcaption {
						margin-top: 2px;
						color: $secondary;
						font-weight: 500;
					}
					margin-top: -30px;
				}
				@include media-breakpoint-down(sm) {
					@include popular-arcadex(
						$CardWidth: 200px,
						$cardHeight: 320px,
						$imgHeight: 176px,
						$card-inner-border-radius: 0px,
						// Retrofy commented out
						// $card-border-radius: 16px
						// Retrofy added in
						$card-border-radius: 2px
					);
					.card-header {
						font-size: 12px;
					}
					.card-body {
						h4 {
							font-size: 16px;
							line-height: 18px;
							font-weight: bold;
						}
						figure {
							margin-top: -30px;
							figcaption {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
}

.not-found-container {
	padding: 50px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.not-found-image {
		width: 500px;
		height: 500px;
		background: url(../assets/images/page-not-found.png) no-repeat center
			center;
		background-size: 500px 500px;
	}
	h1 {
		max-width: 280px;
		color: #fff;
		margin: 0 0 -55px 0;
	}
	p {
		margin: -65px 0 30px 0;
		font-size: 18px;
		line-height: 25px;
	}
	.primary-button {
		width: 200px;
		color: $white;
		background: $miggsterRed;
		outline: none;
		text-align: center;
		border-radius: 6px;
		padding: 1rem;
		font-weight: 600;
		text-transform: uppercase;
		margin: 0 auto;
		box-shadow: 0 2px 10px rgba($black, 0.3);
		cursor: pointer;
		transition: all 0.5s ease;
		border: none !important;
		&:hover {
			background: lighten($miggsterRed, 15%);
		}
		&:active {
			transition: all 0.2s ease;
			background: darken($miggsterRed, 15%);
			box-shadow: 0 1px 4px rgba($black, 0.5);
		}
	}
	@include media-breakpoint-down(lg) {
		.not-found-image {
			width: 420px;
			height: 420px;
			background-size: 420px 420px;
		}
	}
	@include media-breakpoint-down(md) {
		height: calc(100vh - 144px);
		padding: 30px 0;
	}
	@include media-breakpoint-down(xs) {
		.not-found-image {
			width: 340px;
			height: 340px;
			background-size: 340px 340px;
		}
		h1 {
			max-width: 145px;
			font-size: 21px;
			line-height: 26px;
			margin: 0 0 -35px 0;
		}
		p {
			color: rgba($white, 0.8);
			margin: -55px 0 25px 0;
			max-width: 160px;
			font-size: 14px;
			line-height: 21px;
		}
	}
}

.mobile-chat-container {
	display: none !important;
	opacity: 0 !important;
	transition: opacity 1s;
	// display: flex;
	// opacity: 1;
	// transition: opacity 1s;
	z-index: 1025 !important;

	&.fadein {
		display: flex !important;
		opacity: 1 !important;
	}

	@include media-breakpoint-down(md) {
		justify-content: center;
		align-items: center;
		// margin-bottom: calc((100vh - 100vw) / 10);
		margin-bottom: 45px;
		height: 10px;
		width: 10px;
		margin-right: -20px;

		.fa-comments {
			width: 20px;
			height: 20px;
		}
	}
}

// Retrofy added in
/* RETRO BORDERS */
.retro-border {
	display: inline-block;
	position: relative;
}
.retro-border:before,
.retro-border:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	box-sizing: content-box;
}
.retro-border:before {
	top: -4px;
	left: 0;
	border-top: 4px #383847 solid;
	border-bottom: 4px #383847 solid;
}
.retro-border:after {
	left: -4px;
	top: 0;
	border-left: 4px #383847 solid;
	border-right: 4px #383847 solid;
}

.retro-dotted-line {
	border-top: 2px dotted rgba(255, 255, 255, 0.4);
	// border-top: 2px solid rgba(219, 52, 235, 0.7);
}

.retro-dotted-line-bottom {
	border-bottom: 2px dashed rgba(255, 255, 255, 0.5);
	// border-top: 2px solid rgba(219, 52, 235, 0.7);
}

.retro-black-dotted-line {
	border-top: 2px dashed gray;
}

.retro-black-dotted-line-bottom {
	border-bottom: 2px dashed gray;
}

/* RETRO BUTTONS */
.retro-btn {
	cursor: pointer;
	border: 0px;
	// background: #c9123f;
	display: inline-block;
	position: relative;
	text-align: center;
	font-size: 12px;
	padding: 5px;
	// font-family: 'Press Start 2P';
	text-decoration: none;
	text-transform: uppercase;
	color: gray;
	outline: none;
	border: 0px;
	// box-shadow: inset 0px 0px 0px 0px #c9143f;
	// text-shadow: 0px -2px #8d0c2c;
	// width: 45%;
	// min-width: 45%;
	width: 160px;
	min-width: 160px;
	z-index: 1; /* Increment up if rollover not working */
	@include media-breakpoint-down(lg) {
		// width: 52%;
		// min-width: 52%;
		width: 150px;
		min-width: 150px;
		font-size: 11px;
	}
	@include media-breakpoint-down(md) {
		// width: 65%;
		// min-width: 65%;
		width: 140px;
		min-width: 140px;
	}
	@include media-breakpoint-down(sm) {
		width: 130px;
		min-width: 130px;
		font-size: 10px;
	}
	@include media-breakpoint-down(xs) {
		// width: 100%;
		// min-width: 100%;
		width: 100px;
		min-width: 100px;
		font-size: 8px;
	}
}
.retro-btn:hover,
.retro-btn:focus {
	color: white;
	// color: black;
	// background-color: white;
	// background: #c9123f;
	// box-shadow: inset 0px 0px 0px 4px #a40e34;
	outline: 0;
}

.retro-btn:hover .all-button-arrow {
	margin-left: 3px;
}

.all-button-arrow {
	position: absolute;
}

.retro-btn:active {
	// background: #a40e34;
}
.retro-btn:before,
.retro-btn:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	box-sizing: content-box;
}
.retro-btn:before {
	top: -3px;
	left: 0;
	// border-top: 4px #8d0c2c solid;
	// border-top: 3px #c9123f solid;
	// border-bottom: 3px #8d0c2c solid;
	border-top: 3px gray solid;
	border-bottom: 3px gray solid;
}
.retro-btn:after {
	left: -3px;
	top: 0;
	// border-left: 3px #c9123f solid;;
	// border-right: 3px #8d0c2c solid;
	border-left: 3px gray solid;
	border-right: 3px gray solid;
}

.retro-btn-wide {
	width: 170px;
	min-width: 170px;
	@include media-breakpoint-down(lg) {
		// width: 52%;
		// min-width: 52%;
		width: 160px;
		min-width: 160px;
		font-size: 11px;
	}
	@include media-breakpoint-down(md) {
		// width: 65%;
		// min-width: 65%;
		width: 150px;
		min-width: 150px;
	}
	@include media-breakpoint-down(sm) {
		width: 140px;
		min-width: 140px;
		font-size: 10px;
	}
	@include media-breakpoint-down(xs) {
		// width: 100%;
		// min-width: 100%;
		width: 120px;
		min-width: 120px;
		font-size: 8px;
	}
}

.top-bg-text {
	font-size: 34px;

	@include media-breakpoint-down(lg) {
		font-size: 32px;
	}
	@include media-breakpoint-down(md) {
		font-size: 30px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 26px;
	}
	@include media-breakpoint-down(xs) {
		font-size: 22px;
	}
}

.dark-gradient-section {
	background-color: #202029 !important;
}
