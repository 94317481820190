@import "../../../../styles/shared.scss";
.modal {
  .change-password {
    .input-group-wrapper {
      position: relative;
    }

    .bottom-margin {
      margin-bottom: 80px;
    }
  }
  .iphone-SE-top {
    &.mt {
      margin-top: 2rem !important;
    }
  }
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.75);
}

