@import "../../styles/shared.scss";

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: block;
    position: absolute;
    background: lighten($miggsterBGLightGrey, 6%);
    min-width: 160px;
    box-shadow: 0px 9px 40px -8px rgba(0, 0, 0, 0.5);
    z-index: 999999;
    text-align: center;
    border-radius: 13px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 5px;
    padding-bottom: 5px;
    /* Links inside the dropdown */
    a,
    .a,
    .dropdown-item {
      padding: 0.25rem 1.5rem;
      text-decoration: none;
      display: block;
      font-size: 16px;
      font-weight: 500;
      color: rgba($white, 0.5);
      transition: all 0.5s ease;
    }

    /* Change color of dropdown links on hover */
    a:hover,
    .a:hover,
    .dropdown-item:hover {
      color: $white;
      background-color: rgba(0,0,0,.2);
    }

    .border-line {
      box-shadow: inset 0 -1px 0 0 rgba($black, 0.4);
      height: 1px;
    }
  }
}


/* Show the dropdown menu on hover */
.dropdown-content-hidden {
  display: none;
}

.dropdown-content-not-visible {
  visibility: hidden;
}
.App {
  padding-left: 100%;
}


// .ink.animate {
//   -webkit-animation: ripple 0.5s linear;
//   -moz-animation: ripple 0.5s linear;
//   -ms-animation: ripple 0.5s linear;
//   -o-animation: ripple 0.5s linear;
//   animation: ripple 0.5s linear;
//   height: 24px; width: 24px; top: 1px; left: 6px;
// }
