@import "../../styles/shared.scss";

.select2 .dropdown {
  position: relative;
  width: 100%;
  // border: 1px solid red;

  // Retrofy added in
  border: 2px solid white;
  margin-bottom: 8px;

  .selection-heading {
    padding: 20px 15px 15px 15px;
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 500;
    color: rgba($white, 0.2);
    box-shadow: inset 0 -1px 0 0 $pale-blue;
  }

  .down-arrow-dropdown {
    position: absolute;
    right: 0px;
    top: 6px;
    z-index: 4;
    pointer-events: none;
    transition: all 0.5s ease-in-out;

    &.openArrow {
      right: 10px;
      top: 4px;
      transform: rotate(180deg);
    }
  }

  &--open .dropdown__list {
    display: block;
  }
}

.select2 .dropdown__list {
  position: absolute;
  z-index: 3;
  display: none;
  top: -15px;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: $miggsterGrey;
  // Retrofy commented out
  // border-radius: 8px;
  // Retrofy added in
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  ul {
    max-height: 300px;
    overflow: auto;
    margin-bottom: 0px;
  }
  li {
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    &:hover {
      color: $white;
      background-color: rgba($pale-blue, 0.3);
    }
  }
}

.select2 input {
  padding-right: 15px !important;
  // Retrofy added in
  padding-left: 10px !important;

  &:focus {
    outline: none;
    caret-color: transparent !important;
  }
  cursor: pointer;
  caret-color: transparent !important;
}
