header {
  .navbar-light {
    height: 72px;
    box-shadow: 0 2px 10px -3px rgba(0,0,0,0.5);
    .navbar-brand {
      display: inline-block;
      padding: 0;
      margin-right: 0;
      line-height: inherit;
      white-space: nowrap;
      // Retrofy commented out
      // max-width: 14rem;
      // Retrofy added in
      max-width: 12rem;
      @include media-breakpoint-down(md) {
        margin-left: 20px;
      }
      
      width: 100%;
      .mainLogo {
        width: 100%;
        height: 100%;
        margin: auto;
      }
      @include hover-focus {
        text-decoration: none;
      }
    }
    .btn-mtnThemeMainColor {
      padding: 14px 40px;
    }
    .icon-menu {
      width: auto;
      cursor: pointer;
    }
    .nav-item {
      position: relative;
      .nav-link {
        transition: all 0.5s ease;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        padding-right: $navbar-nav-link-padding-x;
        padding-left: $navbar-nav-link-padding-x;
        position: relative;
        &.mtn  {
          @include hover-focus {
            color: rgba($white, 1);
          }
        }
        &.mtn {
          &.active {
            color: $mtnThemeMainColor;
            &::after {
              background-color: $mtnThemeMainColor;
            }
          }
        }
      }
      &.active {
        &::after {
          content: "";
          position: absolute;
          height: 3px;
          width: 100%;
          left: 0;
          top: -20px;
          background-color: $miggsterRed;
        }
        &.logged-in {
          &::after {
            top: 0;
          }
        }
      }
    }
    &.navbar {
      &.isAnyModalOpen {
        @include media-breakpoint-down(md) {
          z-index: 1030;
        }
      }
      z-index: 1025;
      @include media-breakpoint-only(lg) {
        .navbar-nav {
          .nav-link {
            padding-left: 0.8rem;
            padding-right: 0.8rem;
          }
        }
      }
      @include media-breakpoint-down(md) {
        padding: 0px 1rem;
      }
    }
    &.nav-main {
      .nav-item {
        .nav-link {
          height: 72px;
          line-height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 1.2rem;
          padding-right: 1.2rem;
          .search-icon {
            max-width: 20px;
            max-height: 20px;
          }
          &.icon-link {
            .icon-container {
              display: block;
              width: 24px;
              height: 24px;
              background-size: 24px 24px;
              background-repeat: no-repeat;
              background-position: center;
              transition: all 0.5s ease;
              opacity: 0.4;
            }
            &.chat {
              padding-left: 2rem;
              padding-right: 0.6rem;
              .icon-container {
                // Eurofy added in
                background-image: url(../assets/images/svg/chat-nav-white.svg);
                // Eurofy commented out
                // background-image: url(../assets/images/svg/chat-nav-white-retro.svg);
              }
            }
            &.notifications {
              padding-left: 0.6rem;
              padding-right: 0.6rem;
              .icon-container {
                margin-top: -1px;
                // Eurofy added in
                background-image: url(../assets/images/svg/notification-nav-white.svg);
                // Eurofy commented out
                // background-image: url(../assets/images/svg/notification-nav-white-retro.svg);
              }
            }
          }
          &:hover, &:active {
            .icon-container {
              opacity: 1;
            }
          }
        }
        &:last-child {
          .nav-link {
            padding-right: 0px;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
          }
        }
      }
    }
  }
}
.left-arrow-icon {
  transform: rotate(180deg);
}
.message-count {
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  border-radius: 50px;
  position: absolute;
  right: 0;
  top: 20%;
  font-size: 10px;
  min-width: 20px;
  text-align: center;
}
