//Extentiosn


//Footer
footer {
  background-color: $miggsterGrey;
  border-top: 1px solid lighten($pale-blue, 5%);
  
  .footer-logo {
    width: 64%;
    margin-bottom: 20px;
    @include media-breakpoint-down(lg) {
        width: 79%;
    }
  }

  .select-dropdown {
    width: 64%;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg) {
        width: 79%;
    }
    p {
        margin: 0 10px 0 0;
        color: rgba($white, 0.3);
    }
    .select2 {
        flex: 1;
      .dropdown {
        .form-control {
          background: transparent;
          color: $white;
          border: 1px solid rgba($white, 0.1);
          outline: none !important;
          box-shadow: none !important;
        }
        .down-arrow-dropdown {
          background-color: $miggsterGrey;
          right: 5px;
        }
        .dropdown__list {
            min-width: 220px;
            top: auto;
            bottom: 35px;
        }
      }
    }
  }


  .footer-sec-1,
  .footer-sec-2,
  .footer-sec-3,
  .footer-sec-4 {
    padding-top: 5rem;
    padding-bottom: 4.4rem;
    ul {
      margin-bottom: 0px;

      li {
        a,
        .a {
          line-height: 2.3rem;
          color: rgba($white, 0.3);
          transition: all 0.5s ease;

          &:hover,
          &:focus {
            color: $white;
          }

          &:active {
            color: $white;
          }
        }
        .socialMedia {
          .list-inline-item {
            a {
              width: 18px;
              height: 18px;
              display: block;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 18px 18px;
            }
            &.instagram {
              a {
                background-image: url(../assets/images/svg/instagram-dark.svg);
                &:hover {
                  background-image: url(../assets/images/svg/instagram-white.svg);
                }
              }
            }
            &.facebook {
              a {
                background-image: url(../assets/images/svg/facebook-dark.svg);
                &:hover {
                  background-image: url(../assets/images/svg/facebook-white.svg);
                }
              }
            }
            &.twitter {
              a {
                background-image: url(../assets/images/svg/twitter-dark.svg);
                &:hover {
                  background-image: url(../assets/images/svg/twitter-white.svg);
                }
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      ul {
        min-width: 200px;
        li {
          font-size: 16px;
          font-weight: normal;
          line-height: 2.5rem;
        }
      }
      .footer-logo {
        padding-top: 2rem;
        padding-bottom: 0px;
        .mainLogo {
          width: 10rem;
        }
      }

      padding-top: 2rem;
      padding-bottom: 4rem;
    }
  }

  .pl-custom {
    padding-left: 5%;
    @include media-breakpoint-down(sm) {
      padding-left: 15px;
    }
  }

  .copyright {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    background: $miggsterRed;
    p {
      margin-bottom: 0px;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
    }
    @include media-breakpoint-down(md) {
      height: 25px;
    }
  }

  .gutter-space {
    padding: 0px 15px;

    [class^="col"] {
      padding-right: 5px;
      padding-left: 5px;
    }
    @include media-breakpoint-up(md) {
      margin: 0 auto;
      padding: 10px 0;
      max-width: 500px;
    }
  }

  h4 {
    font-weight: 500;
    color: $white;
  }
  &.mb-65-responsive {
    @include media-breakpoint-down(sm) {
      position: relative;
      bottom: 65px;
    }
  }
  .footer-wrapper {
    box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.3);
  }
  .pb-20{
    padding-bottom: 20px !important;
  }

  .footer-afterLogin {
    background: $miggsterGrey;
    box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-wrap: nowrap;
    a {
      padding: 12px 0 10px 0;
      border-top: 2px solid transparent;
      display: block;
      .icon {
        width: 22px;
        height: 22px;
        opacity: 0.4;
        margin-bottom: 0.25rem;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 22px 22px;
        transition: opacity 0.5s ease;
      }
      &.dashboard {
        .icon {
          background-image: url(../assets/images/svg/dashboard-nav-white.svg);
        }
      }
      &.giveaways {
        .icon {
          // Retrofy commented out
          // background-image: url(../assets/images/svg/giveaways-nav-white.svg);
          // Retrofy added in
          background-image: url(../assets/images/svg/giveaways-nav-white-retro.svg);
        }
      }
      &.games {
        .icon {
          background-image: url(../assets/images/svg/games-nav-white.svg);
        }
      }
      &.chat {
        .icon {
          // Retrofy commented out
          // background-image: url(../assets/images/svg/chat-nav-white.svg);
          // Retrofy added in
          background-image: url(../assets/images/svg/chat-nav-white-retro.svg);
        }
      }
      &.notifications {
        .icon {
          // Retrofy commented out
          // background-image: url(../assets/images/svg/notification-nav-white.svg);
          // Retrofy added in
          background-image: url(../assets/images/svg/notification-nav-white-retro.svg);
        }
      }
      figure {
        figcaption {
          font-size: 10px;
          font-weight: 500;
          opacity: 0.4;
          color: $white;
          transition: opacity 0.5s ease;
        }
      }
      &.active {
        border-top-color: $miggsterRed;
        background: linear-gradient(to bottom, rgba(61,28,28,0.8) 0%, rgba(61,28,28,0) 100%);
        background-image: -moz-linear-gradient(to bottom, rgba(61,28,28,0.8) 0%, rgba(61,28,28,0) 100%);
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(61,28,28,0.8)), to(rgba(61,28,28,0)));
        background: -o-linear-gradient(top, rgba(61,28,28,0.8) 0%, rgba(61,28,28,0) 100%);
        .icon,
        figure figcaption {
          opacity: 1;
        }
      }
      &:hover {
        .icon, figure figcaption {
          opacity: 1;
        }
      }
      &:active {
        .icon, figure figcaption {
          transition: opacity 0.2s ease;
          opacity: 0.2;
        }
      }
    }
    [class^="col"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.badge-notification-count-responsive {
  position: absolute;
  left: 48%;
  right: auto;
  margin-left: 10px;
  top: -4px;
}
