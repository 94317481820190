@import "../../../styles/shared.scss";
.modalV3 {
  &.modal {
    &.unsubscribeModal {
      .modal-content {
        background-color: $miggsterGrey;
        color: $mtnThemeMainColor;
        border-radius: 24px !important;
        overflow: hidden;
        .modal-body {
          padding: 3.5rem;
        }
      }
      .modal-footer {
        padding: 0 !important;
        @include media-breakpoint-down(md) {
          height: auto !important;
        }
      }
      .modal-dialog {
        @include media-breakpoint-up(md) {
          max-width: 332px;
        }
      }
      .popup-dismiss {
        position: absolute;
        right: 25px;
        cursor: pointer;
        top: 25px;
      }
    }
  }
}
