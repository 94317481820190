@import "../../styles/shared.scss";
.modal-footer{
  .bg-primary{
    background-color : $miggsterRed !important;
  }
}
.modal-content{
  // background-color: #231f20;
}
.modalV1 {
  &.modal {
    .modal-dialog-centered {
      @include media-breakpoint-down(sm) {
        height: 100vh;
        margin: 0px;
        .modal-content {
          border: 0px;
          border-radius: 0px;
        }
      }
    }
    .modal-content {
      border-radius: 5px;
      overflow: hidden;
      .modal-body {
        padding: 3.5rem;
        .swiper-container {
          margin: 0 !important;
        }
        .swiper-container .swiper-wrapper .swiper-slide:hover {
          transform: translateY(0) !important;
        }
        .swiper-container .swiper-wrapper .swiper-slide:active {
          transform: scale(1) !important;
        }
      }
    }
    @include media-breakpoint-down(md) {
      &.without-footer {
        .modal-dialog {
          .modal-content {
            .modal-body {
              bottom: 0px;
            }
          }
        }
      }
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      z-index: 1029;

      .modal-dialog {
        position: fixed;
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        max-width: 100%;
        .modal-content {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 0;
          box-shadow: none;
          border: none;
          .modal-header {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 52px;
            border: 0;
          }
          .modal-body {
            &.landscape {
              position: absolute;
              overflow-y: auto;
            }
            position: fixed;
            top: 72px;
            bottom: 52px;
            width: 100%;
            font-weight: 300;
            overflow: hidden;
            -webkit-overflow-scrolling: touch;
            padding-top: 100px;
            padding-left: 50px;
            padding-right: 50px;
            @media screen and (max-width: 340px) {
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 80px;
            }
          }
          .modal-footer {
            position: absolute;
            right: 0;
            bottom: 0px;
            left: 0;
            height: 52px;
            width: 100%;
          }
        }
      }
    }
    .card-popup {
      position: relative;
      z-index: 100;
      .mt-negative-4 {
        margin-top: -5px;
        background: #fff;
        padding: 5px 20px;
        font-size: 12px;
        font-weight: 500;
      }
      .gutter-space {
        padding: 0px 15px;
        [class^="col"] {
          padding-right: 0px;
          padding-left: 0px;
        }
      }
      .pt-custom-1 {
        padding-top: 1.8rem;
      }
      .creat-account {
        font-size: 16px;
      }
    }
    .popup-dismiss {
      position: absolute;
      right: 25px;
      cursor: pointer;
      top: 25px;
      @include media-breakpoint-down(md) {
        top: 80px;
      }
    }
  }

  .cross-background {
    position: absolute;
    background: radial-gradient(
      circle at 111% 58%,
      #3e68ff,
      #1a46e5 50%,
      #0024a8
    );
    &:after {
      content: "";
      background-image: url("../../assets/images/login-shape-secondary.png");
      position: absolute;
      background-repeat: no-repeat;
    }
    &.web {
      bottom: -5px;
      right: 0px;
      clip-path: polygon(100% 55%, 100% 100%, -50% 100%);
      -webkit-clip-path: polygon(100% 55%, 100% 100%, -50% 100%);
      width: 100%;
      height: 100%;
      &:after {
        background-size: 100%;
        background-position: top left;
        top: 55%;
        right: 10%;
        width: 100%;
        height: 100%;
      }
    }
    &.mobile {
      margin-top: -190px;
      left: 0;
      -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
      width: 100%;
      height: 100%;
      &:after {
        background-position: top right;
        background-size: 100% 100%;
        top: 0;
        left: -20px;
        width: 80%;
        height: 100%;
      }
    }
  }
  .popup-responsive {
    padding-top: 72px;

    &.position-fixed {
      .cross-background {
        background: $secondary;
        width: 100%;
        height: 100%;
        position: absolute;
        margin-top: -190px;
        left: 0;
        -webkit-clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%);
      }
      .iphone-SE-creat-account {
        &.mt-4 {
          margin-top: 0.5rem !important;
        }
      }
      .privacy-policy {
        .mt-2 {
          margin-top: 0px !important;
        }
      }
    }
  }
  .iphone-SE-top {
    &.mt-5 {
      margin-top: 1.8rem !important;
    }
  }
}
@include media-breakpoint-down(md) {
  .modal-backdrop {
    z-index: 1028 !important;
  }
}

.modal-content {
  .create-tutorial {
    .swiper-wrapper {
      width: 100% !important;
    }
  }
}
