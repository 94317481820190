@import "../../../styles/shared.scss";
.Arcadex-play {
    .bannerImg {
        img {
            width: calc(100% - 30px) !important;
            margin: 0 15px;
            max-height: 300px;
        }
    }
    .modal-body {
        top: 0 !important;
        padding: 0px !important;
        justify-content: center;
        display: flex;
        align-items: center;
    }
    .card-body {
        @include media-breakpoint-down(md) {
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 0;
        }
        .player-info {
            .avatar {
                border-radius: 50%;
                box-shadow: 0 6px 26px -8px rgba(0, 0, 0, 0.5);
                border:2px solid transparent;
                margin-top: -40px;
                img {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    object-fit: cover;         
                }
            }
        }
        .gift-img {
            margin-bottom: 45px; 
            img {
                max-width: 250px;
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 30px; 
                h6 {
                    font-size: 14px;
                }
                img {
                    max-height: 180px;
                }
            }
        }
        .join-btn {
            max-width: 300px;
            margin: 0 auto;
            @include media-breakpoint-down(xs) {
                width: 100%;
                max-width: 100%;
            }
        }
        .cancel-btn {
            font-size: 16px;
        }
    }
}

.mobile-scren {
    .bannerImg {
        img {
            max-height: 144px;
            object-fit: cover;
            object-position: top;
        }
    }
    .card-body {
        padding: 0px;
        .player-info {
            background: #fff;
            box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24);
            padding: 10px 15px;
        }
        .gift-wrapper {
            padding: 0px 30px;
        }
    }
    @include media-breakpoint-up(sm) { 
        .bannerImg {
            img {
                max-height: 270px;
            }
        } 
    }
}

