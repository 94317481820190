.card {
  &.card-arcadexMobi {
    @include card-arcadexMobi();
    @include media-breakpoint-down(sm) {
      @include card-arcadexMobi(
        $CardWidth: 200px,
        $cardHeight: 176px,
        $imgHeight: 176px,
        // Retrofy commented out
        // $card-inner-border-radius: 16px,
        // $card-border-radius: 16px
        // Retrofy added in
        $card-inner-border-radius: 2px,
        $card-border-radius: 2px
      );
    }
  }
  &.popular-arcadex {
    margin-bottom: 20px;
    @include popular-arcadex();
    &.dark {
      @include box-shadow(0px, 3px, 10px, darken($info, 10%));
    }
    .card-body {
      padding: 0rem 2rem;
      @include media-breakpoint-down(sm) {
        padding: 0rem 1.2rem;
      }
      h4 {
        color: $miggsterGrey;
        line-height: 24px;
      }
    }
    figure {
      margin-bottom: 7px;
      img {
        margin-left: -20px;
      }
      figcaption {
        margin-top: 2px;
        color: $secondary;
      }
      margin-top: -50px;
    }
    @include media-breakpoint-down(sm) {
      @include popular-arcadex(
        $CardWidth: 200px,
        $cardHeight: 320px,
        $imgHeight: 176px,
        // Retrofy commented out
        // $card-inner-border-radius: 0px,
        // $card-border-radius: 16px
        // Retrofy added in
        $card-inner-border-radius: 2px,
        $card-border-radius: 2px
      );
      .card-header {
        font-size: 12px;
      }
      .card-body {
        h4 {
          font-size: 16px;
          line-height: 18px;
          font-weight: bold;
        }
        figure {
          margin-top: -30px;
          img {
            margin-left: -10px;
          }
          figcaption {
            font-size: 12px;
          }
        }
      }
    }
  }
  &.quick-play {
    background-color: transparent;
    @include quick-play();
    @include media-breakpoint-down(sm) {
      @include quick-play(
        $CardWidth: 200px,
        $cardHeight: 176px,
        $imgHeight: 176px,
        // Retrofy commented out
        // $card-inner-border-radius: 16px,
        // $card-border-radius: 16px
        $card-inner-border-radius: 2px,
        $card-border-radius: 2px
      );
    }
    small {
      font-size: 12px;
      color: rgba($white, 0.2);
      font-weight: 500;
    }
    transition: all 0.3s ease;
    h4 {
      transition: all 0.3s ease;
    }
    .card-descriptor > * {
      padding: 5px 0;
      font-size: 14px;
      line-height: 22px;
      color: rgba($miggsterTextHeading, 0.8);
    }
    &:hover {
      h4 {
        color: $white;
      }
    }
  }
  &.popular-host {
    @include popular-host();
    figure {
      img {
        position: relative;
      }
      .status {
        position: absolute;
        top: 15px;
        right: 25px;
        width: 18px;
        height: 18px;
        background-image: radial-gradient(circle at 50% 31%, #73ffb3, #50de80);
        box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        border: 2px solid white;
        @include media-breakpoint-down(sm) {
          top: 5px;
          right: 13px;
        }
      }
      figcaption {
        font-size: 16px;
        line-height: 1.38;
        font-weight: 500;
        margin-bottom: 0px;
        @include media-breakpoint-down(sm) {
          font-size: 12px;
        }
        width: 120px;
        overflow: hidden;
      }
    }
    @include media-breakpoint-down(sm) {
      figcaption {
        font-size: 12px;
      }
    }
  }
  &.free-to-play-game {
    @include free-to-play-game();

    @include media-breakpoint-down(lg) {
      @include free-to-play-game(
        $CardWidth: 162px,
        $cardHeight: 162px,
        $imgHeight: 162px
      );
    }
    @include media-breakpoint-down(md) {
      @include free-to-play-game(
        $CardWidth: 150px,
        $cardHeight: 150px,
        $imgHeight: 150px
      );
    }
    @include media-breakpoint-down(sm) {
      @include free-to-play-game(
        $CardWidth: 150px,
        $cardHeight: 150px,
        $imgHeight: 150px,
      );
    }
    @include media-breakpoint-down(xs) {
      @include free-to-play-game(
        $CardWidth: calc(50vw - 30px),
        $cardHeight: calc(50vw - 30px),
        $imgHeight: calc(50vw - 30px)
      );
    }
  }
  &.prize-pool {
    margin-bottom: 20px;
    @include prize-pool();
    .card-body {
      figure {
        img {
          max-width: 120px;
          @include media-breakpoint-down(sm) {
            max-width: 90px;
          }
        }
        figcaption {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        margin-top: -105px;
      }
      h4 {
        color: $miggsterGrey;
        line-height: 26px;
      }
    }
    @include media-breakpoint-down(sm) {
      @include prize-pool(
        $CardWidth: 200px,
        $cardHeight: 360px !important,
        $imgHeight: 170px
      );
      .card-body {
        h4 {
          font-size: 16px;
          line-height: 18px;
          font-weight: bold;
        }
      }
    }
  }
  &.enter-raffles {
    @include enter-raffles();

    .card-body {
      padding: 0px;
      position: relative;
      .card-info {
        position: absolute;
        bottom: 0px;
        background: rgba($bluish-grey, 0.9);
        background: lighten($miggsterBGLightGrey, 10%);
        min-height: 150px;
        padding: 15px;
        width: 100%;
        figure {
          figcaption {
            min-height: 50px;
          }
          margin-top: -50px;
        }
        .btn-pink, .btn-secondary, .btn-mtnBlack, .btn-mtnThemeMainColor {
          &.btn-block {
            line-height: 20px;
            margin-top: 5px;
            margin-bottom: 7px;
            font-size: 14px;
          }
        }
        &.active-arcadex {
          background: rgba($secondary, 0.9);
        }

        @include media-breakpoint-down(sm) {
          .profile-pic-56 {
            width: 56px;
            height: 56px;
          }
        }
      }
    }
    .card-footer {
      background: transparent;
      margin-top: -25px;
      background-color: rgb(206, 206, 206);
      padding-bottom: 18px;
      .raffle-timer {
        img {
          max-width: 24px;
          max-height: 24px;
        }
        position: relative;
        z-index: 1;
      }
    }
  }
  &.card-my-team {
    @include card-my-team();
    @include box-shadow(0px, 3px, 10px, darken($info, 10%));

    img {
      width: 100%;
    }
  }
}

.ends-in {
  margin-top: 8px;
  font-weight: 600;
}
