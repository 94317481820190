.close_notification{
    position: absolute;
    top:4px;
    right:20px;
    span{
        img{
            width: 16px;
        }
    }
}
.icon-close {
    cursor: pointer;
}