@mixin inputwithoutlabel {
    .effect-1 {
        border: 0;
        padding: 7px 0;
        border-bottom: 1px solid rgba($white, 0.1);
    }
    .effect-1~.focus-border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $white;
        transition: 0.4s;
    }
    .effect-1:focus~.focus-border {
        width: 100%;
        transition: 0.4s;
    }

}

@mixin inputwithlabel() {
    .effect-17 {
        border: 0;
        padding: 7px 0;
        border-bottom: 1px solid red;
    }
    .effect-16 {
        border: 0;
        padding: 7px 0;
        border-bottom: 1px solid rgba($white, 0.1);
    }
    .effect-16~.focus-border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $white;
        transition: 0.4s;
    }
    .effect-17~.focus-border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: red;
        transition: 0.4s;
    }
    .effect-16:focus~.focus-border,
    .has-content.effect-16~.focus-border {
        width: 100%;
        transition: 0.4s;
    }
    .effect-17:focus~.focus-border,
    .has-content.effect-17~.focus-border {
        width: 100%;
        transition: 0.4s;
    }
    .effect-16~label {
        position: absolute;
        left: 0;
        width: 100%;
        top: 9px;
        color: rgba($white, 0.4);
        transition: 0.3s;
        z-index: -1;
        letter-spacing: 0.5px;
        font-size: 15px;
        font-weight: 500;
    }
    .effect-17~label {
        position: absolute;
        left: 0;
        width: 100%;
        top: 9px;
        color: rgba($white, 0.4);
        transition: 0.3s;
        z-index: -1;
        letter-spacing: 0.5px;
        font-size: 15px;
        font-weight: 500;
    }
    .effect-16:focus~label,
    .has-content.effect-16~label {
        top: -16px;
        font-size: 12px;
        color: rgba($white, 0.4);
        transition: 0.3s;
    }
    .effect-17:focus~label,
    .has-content.effect-17~label {
        top: -16px;
        font-size: 12px;
        color: #3c4055;
        transition: 0.3s;
    }
    .has-content.effect-16~.focus-border {
        background-color: #3c4055;
    }
    .has-content.effect-17~.focus-border {
        background-color: red;
    }
}