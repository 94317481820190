@import "../../styles/shared.scss";

.modalV2 {
  &.modal {
    .modal-dialog-centered {
      @include media-breakpoint-down(sm) {
        height: 100vh;
        margin: 0px;
        .modal-content {
          border: 0px;
          border-radius: 0px;
        }
      }
    }
    padding-right: 0px !important;
    .modal-content {
      border-radius: 5px;
      border: 0px;
      overflow: hidden;
      background-color: transparent !important;
      .modal-body {
        // padding: 3.5rem;
        padding: 0;
        background-color: $miggsterBGLightGrey;
        .modal-head {
          padding: 0 1.5rem;
          height: 70px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: darken($miggsterGrey, 1%);
        }
      }
    }
    .popup-dismiss {
      position: absolute;
      right: 25px;
      cursor: pointer;
      top: 25px;
      @include media-breakpoint-down(md) {
        top: 80px;
      }
    }
  }
  overflow: hidden;

  .card-popup {
    position: relative;
    z-index: 100;
    padding: 3.5rem;
    @include media-breakpoint-down(md) {
      padding: 2rem;
    }
    .mt-nagative-4 {
      margin-top: -5px;
      background: #fff;
      padding: 5px 0px;
      font-size: 12px;
      font-weight: 500;
      left: calc(50% - 30px);
      width: 60px;
      text-align: center;
    }
    .gutter-space {
      padding: 0px 15px;
      [class^="col"] {
        padding-right: 0px;
        padding-left: 0px;
      }
    }
    .custom-top {
      padding-top: 1.8rem;
    }
    .creat-account {
      font-size: 16px;
    }
  }

  .cross-background {
    position: absolute;
    background: radial-gradient(
      circle at 111% 58%,
      #3e68ff,
      #1a46e5 50%,
      #0024a8
    );
    &:after {
      content: "";
      background-image: url("../../assets/images/login-shape-secondary.png");
      position: absolute;
      background-repeat: no-repeat;
    }
    &.mobile {
      margin-top: -190px;
      left: 0;
      -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
      width: 100%;
      height: 100%;
      &:after {
        background-position: top right;
        background-size: 100% 100%;
        top: 0;
        left: -20px;
        width: 80%;
        height: 100%;
      }
      &.adjust {
        max-height: 230px;
      }
    }
    &.mtn {
      background: radial-gradient(
      circle at 111% 58%,
      #000000,
      #000000 50%,
      #000000
    );
      &:after {
        background-image: none;
      }
    }
  }
  .crossbackground-web {
    width: 100%;
    height: 100%;
    clip-path: url(#myClipPath);
  }
  .cross-box {
    position: absolute;
    top: 375px;
    left: 0;
    &.no-social-btn {
      top: 320px;
    }
    &.create-player {
      top: 290px;
      &.with-error {
        top: 365px;
      }
    }
    &.with-error {
      top: 425px;
      &.no-social-btn {
        top: 330px;
      }
    }
  }
  &.register {
    .cross-box {
      top: 430px;
      &.no-social-btn {
        top: 355px;
      }
      &.with-error {
        top: 480px;
        &.no-social-btn {
          top: 405px;
        }
      }
    }
  }
  .popup-responsive {
    padding-top: 72px;
    &.position-fixed {
      top: 0px;
      .cross-background {
        &.mtn{
          background: #000000;
        }
        background: $lightish-blue;
        width: 100%;
        height: 100%;
        position: absolute;
        margin-top: -190px;
        left: 0;
        -webkit-clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%);
        -ms-clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 20%, 130% 0, 100% 100%, 0% 100%);
      }
      .iphone-SE-creat-account {
        &.mt-4 {
          margin-top: 0.5rem !important;
        }
      }
      .privacy-policy {
        .mt-2 {
          margin-top: 0px !important;
        }
      }
    }
  }
  .iphone-SE-top {
    &.mt-5 {
      margin-top: 1.8rem !important;
    }
  }
  small {
    font-size: 12px;
  }
}

.registerwithterms .modal-dialog {
  max-width: 700px !important;
}

.modalV2.registerwithterms .cross-box.no-social-btn {
  top: 380px;
}
