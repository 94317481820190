@import "../../../styles/shared.scss";

.modalV2 {
  .create-avatar {
    form {
      .btn {
        color: $white;
        background: $miggsterRed;
        outline: none;
        text-align: center;
        border-radius: 6px;
        padding: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        max-width: 150px;
        margin: 0 auto;
        transition: all 0.5s ease;
        border: none !important;
        &:hover {
          background: lighten($miggsterRed, 15%);
        }
        &:active {
          transition: all 0.2s ease;
          background: darken($miggsterRed, 15%);
        }
      }
    }
    .avatar {
      position: relative;
      margin-bottom: 35px;
      .avatar-wrapper {
        position: relative;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background: $miggsterGrey url('https://dj1sbys2prnc6.cloudfront.net/_general/miggster-loader.gif') no-repeat center;
        background-size: 150px 150px;
        .loading-avatar {
          
        }
        img {
          max-width: 200px;
          max-height: 200px;
        }
        .upload-button {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(255, 255, 255, 0.6);
          padding-top: 0;
          padding-bottom: 0;
          width: 200px;
          margin: 0 auto;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;
          transition: all 0.5s ease;
          &:hover {
            background: rgba(255, 255, 255, 0.8);
          }
          &:active {
            opacity: 0.5;
          }

          img {
            width: 30px;
            height: 30px;
            margin-bottom: 5px;
            border-radius: 0;
          }

          .choose-photo {
            margin: 0;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: center;
            color: $miggsterRed;
          }
        }
        .add-avatar {
          width: 60px;
          height: 60px;
          position: absolute;
          bottom: 5px;
          right: 0px;
          z-index: 2;
          background: $miggsterRed;
          box-shadow: 0 3px 16px -7px rgba(0, 0, 0, 0.5);
          border-radius: 50%;
          padding: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: scale(1);
          transition: all 0.5s ease;
          img {
            transition: all 0.5s ease;
          }
          &:hover {
            opacity: 1;
            img {
              opacity: 0.5;
            }
          }
          &:active {
            opacity: 1;
            transform: scale(0.9);
            box-shadow: 0 1px 6px -7px rgba(0, 0, 0, 0.8);
            transition: all 0.2s ease;
            img {
              opacity: 0.25;
              transition: all 0.2s ease;
            }
          }
        }
        .remove-avatar {
          width: 60px;
          height: 60px;
          position: absolute;
          bottom: 5px;
          right: 0px;
          z-index: 2;
          background: $miggsterRed;
          box-shadow: 0 3px 16px -7px rgba(0, 0, 0, 0.5);
          border-radius: 50%;
          padding: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: scale(1);
          transition: all 0.5s ease;
          img {
            transition: all 0.5s ease;
          }
          &:hover {
            opacity: 1;
            img {
              opacity: 0.5;
            }
          }
          &:active {
            opacity: 1;
            transform: scale(0.9);
            box-shadow: 0 1px 6px -7px rgba(0, 0, 0, 0.8);
            transition: all 0.2s ease;
            img {
              opacity: 0.25;
              transition: all 0.2s ease;
            }
          }
        }
      }
      .file {
        visibility: hidden;
        position: absolute;
      }
    }
  }
  .iphone-SE-top {
    &.mt {
      margin-top: 2rem !important;
    }
  }
}
