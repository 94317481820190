.btn-mtnBlack {
  @include button-theme();
  @include gradient-y(lighten($mtnThemeMainColor, 5%), darken($mtnThemeMainColor, 15%));
  border: none;

  &.dark {
    box-shadow: 0 12px 8px -5px rgba(0, 0, 0, 0.3),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
  }

  &.light {
    box-shadow: 0 12px 11px -5px rgba(255, 87, 133, 0.43),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    @include gradient-y($mtnThemeMainColor, $mtnThemeMainColor);
  }

  &.subscribe {
    @include button-size(
      $padding-y: 8px,
      $padding-x: 60px,
      $font-size: 16px,
      $line-height: 36px,
      $border-radius: 100px
    );
  }
  &.footer {
    @include button-size(
      $padding-y: auto,
      $padding-x: auto,
      $font-size: 16px,
      $line-height: 30px,
      $border-radius: 100px
    );
  }
  &.disabled {
    background-image: linear-gradient(
      to bottom,
      lighten($mtnThemeMainColor, 5%),
      darken($mtnThemeMainColor, 15%)
    );
    cursor: default !important;
    pointer-events: none;
    opacity: 1;
  }
  &:disabled {
    background: lighten($mtnThemeMainColor, 55%);
    border-color: rgba($black, 1);
    color: darken($mtnThemeMainColor, 5%);
    cursor: default !important;
    pointer-events: none;
    opacity: 1;
  }
}
.btn-pink {
  @include button-theme();
  background-image: linear-gradient(
    to bottom,
    rgb(255, 101, 150),
    rgb(237, 42, 81)
  );

  &.dark {
    box-shadow: 0 12px 8px -5px rgba(0, 0, 0, 0.3),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
  }

  &.light {
    box-shadow: 0 12px 11px -5px rgba(255, 87, 133, 0.43),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    @include gradient-y(rgb(237, 42, 81), rgb(237, 42, 81));
  }

  &.subscribe {
    @include button-size(
      $padding-y: 8px,
      $padding-x: 60px,
      $font-size: 16px,
      $line-height: 36px,
      $border-radius: 100px
    );
  }
  &.footer {
    @include button-size(
      $padding-y: auto,
      $padding-x: auto,
      $font-size: 16px,
      $line-height: 30px,
      $border-radius: 100px
    );
  }
  &.disabled {
    background-image: linear-gradient(to bottom, $rosa, $rosa);
    cursor: default !important;
    pointer-events: none;
    opacity: 1;
  }
}
.btn-mtnThemeMainColor {
  @include button-theme();
  text-transform: uppercase;
  background-color: $mtnThemeMainColor;
  border: 1px solid $mtnThemeMainColor;
  &.dark {
    box-shadow: $mtnThemeMainColor;
  }

  &.light {
    box-shadow: $mtnThemeMainColor;
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: lighten($mtnThemeMainColor, 10%);
  }

  &.subscribe {
    @include button-size(
      $padding-y: 8px,
      $padding-x: 60px,
      $font-size: 16px,
      $line-height: 36px,
      $border-radius: 100px
    );
  }
  &.footer {
    @include button-size(
      $padding-y: auto,
      $padding-x: auto,
      $font-size: 16px,
      $line-height: 30px,
      $border-radius: 100px
    );
  }
  &.disabled {
    background-image: linear-gradient(to bottom, $rosa, $rosa);
    cursor: default !important;
    pointer-events: none;
    opacity: 1;
  }
  // &.btn-xs {
  //   @include button-size(
  //     $padding-y: 20px,
  //     $padding-x: 21px,
  //     $font-size: 1.1rem,
  //     $line-height: 1px,
  //     $border-radius: 100px
  //   );
  // }
}
.btn-shade-orange {
  @include button-theme();
  border: medium #c43c0e;
  background-image: linear-gradient(
    to bottom,
    rgb(236, 125, 89),
    rgb(215, 71, 25)
  );

  &.dark {
    box-shadow: 0 12px 8px -5px rgba(0, 0, 0, 0.3),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
  }

  &.light {
    box-shadow: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    @include gradient-y(rgb(215, 71, 25), rgb(236, 125, 89));
  }

  &.subscribe {
    @include button-size(
      $padding-y: 8px,
      $padding-x: 60px,
      $font-size: 16px,
      $line-height: 36px,
      $border-radius: 100px
    );
  }
  &.footer {
    @include button-size(
      $padding-y: auto,
      $padding-x: auto,
      $font-size: 16px,
      $line-height: 30px,
      $border-radius: 100px
    );
  }
  &.disabled {
    background-image: linear-gradient(to bottom, $rosa, $rosa);
    cursor: default !important;
    pointer-events: none;
    opacity: 1;
  }
}
.btn-premium {
  @include button-theme();
  background-image: linear-gradient(to bottom, #9013fe, #5b00ac);
  &.dark {
    box-shadow: 0 12px 8px -5px rgba(0, 0, 0, 0.3),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 12px 8px -5px rgba(0, 0, 0, 0.3),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 12px 8px -5px rgba(0, 0, 0, 0.3),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
  }
  &.light {
    box-shadow: 0 12px 11px -5px rgba(144, 19, 254, 0.2),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 12px 11px -5px rgba(144, 19, 254, 0.2),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 12px 11px -5px rgba(144, 19, 254, 0.2),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
  }
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-image: linear-gradient(to bottom, #5b00ac, #5b00ac);
  }
  &.subscribe {
    @include button-size(
      $padding-y: 8px,
      $padding-x: 60px,
      $font-size: 16px,
      $line-height: 36px,
      $border-radius: 100px
    );
  }
  &.footer {
    @include button-size(
      $padding-y: auto,
      $padding-x: auto,
      $font-size: 16px,
      $line-height: 30px,
      $border-radius: 100px
    );
  }
  &.disabled {
    background-image: linear-gradient(to bottom, $rosa, $rosa);
    cursor: default !important;
    pointer-events: none;
    opacity: 1;
  }
  &.unlock {
    width: 80%;
    display: block;
    line-height: 3;
    background-image: linear-gradient(to bottom, $premium, $premium);
    font-weight: 500;
    @include media-breakpoint-down(sm) {
      width: 90%;
      line-height: 2.5;
    }
  }
}
.btn-gray {
  @include button-theme($light-grey-default);
  background-image: linear-gradient(
    to bottom,
    rgb(224, 228, 232),
    rgb(170, 186, 202)
  );
  &.dark {
    box-shadow: 0 12px 8px -5px rgba(0, 0, 0, 0.3),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 12px 8px -5px rgba(0, 0, 0, 0.3),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 12px 8px -5px rgba(0, 0, 0, 0.3),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
  }
  &.light {
    box-shadow: 0 12px 11px -5px rgb(224, 228, 232),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 12px 11px -5px rgb(224, 228, 232),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 12px 11px -5px rgb(224, 228, 232),
      inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
  }
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    @include gradient-y(rgb(170, 186, 202), rgb(170, 186, 202));
  }
  &.footer {
    @include button-size(
      $padding-y: auto,
      $padding-x: auto,
      $font-size: 16px,
      $line-height: 30px,
      $border-radius: 100px
    );
  }
}
.btn-grey {
  @include button-theme();
  color: $white;
  text-transform: uppercase;
  background-color: #666;
  border-color: transparent;
  &.dark {
    box-shadow: #333;
  }

  &.light {
    box-shadow: #666;
  }

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: darken(#666666, 10%);
  }

  &.subscribe {
    @include button-size(
      $padding-y: 8px,
      $padding-x: 60px,
      $font-size: 16px,
      $line-height: 36px,
      $border-radius: 100px
    );
  }
  &.footer {
    @include button-size(
      $padding-y: auto,
      $padding-x: auto,
      $font-size: 16px,
      $line-height: 30px,
      $border-radius: 100px
    );
  }
  &.disabled {
    background-image: linear-gradient(to bottom, $rosa, $rosa);
    cursor: default !important;
    pointer-events: none;
    opacity: 1;
  }
}
.btn-link {
  text-decoration: none;
  font-weight: bold;
}
.btn-white {
  @include button-theme();
  border-radius: 5px;
  background: #fff;
  color: $premium;
  font-size: 12px;
  font-weight: 500;
  &.premium {
    background: #fff;
    color: $premium;
    width: 144px;
    height: 24px;
    line-height: 1.2;
  }

  &.cancel {
    border: none;
    font-weight: bold;
    background: #fff;
    color: $bluish-grey;
    font-size: 16px;
    box-shadow: inset 0 0 0 2px rgba(224, 228, 232, 1);
  }
}

.rounded-xs {
  border-radius: 30px;
  border-width: 2px;
  height: 32px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;

  &.minw-180 {
    min-width: 180px;
    @include media-breakpoint-down(md) {
      min-width: auto;
    }
  }
  &.maxw-180 {
    max-width: 180px;
    @include media-breakpoint-down(md) {
      max-width: auto;
    }
  }
}

/* -- Ripple effect -------------------------------- */
.ripple-effect {
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
}
.ink {
  position: absolute;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  border-radius: 50%;
  background: $secondary;
  opacity: 1;
}

// animation effect
.ink.animate {
  -webkit-animation: ripple 0.5s linear;
  -moz-animation: ripple 0.5s linear;
  -ms-animation: ripple 0.5s linear;
  -o-animation: ripple 0.5s linear;
  animation: ripple 0.5s linear;
}
@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@-moz-keyframes ripple {
  100% {
    opacity: 0;
    -moz-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@-ms-keyframes ripple {
  100% {
    opacity: 0;
    -ms-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@-o-keyframes ripple {
  100% {
    opacity: 0;
    -o-transform: scale(2.5);
    transform: scale(2.5);
  }
}
