 @import "../../../../styles/shared.scss";
 .clearfix{
     clear: both;
     width: 100%;
 }
.chatLoader{
  margin: auto !important;
 .loader-mtn {
    // margin: 120px !important;   
    margin: 0 auto !important;
  }
}
  // @media (max-width: 768px) {
  //   .chatLoader{
  //       .loader-mtn {
  //           margin: 110px !important;   
  //       }
  //   }
  // }
  // @media (max-width: 480px) {
  //   .chatLoader{
  //       .loader-mtn {
  //           margin: 95px !important;        
  //       }
  //   }
  // }
  // @media (max-width: 320px) {
  //   .chatLoader{
  //       .loader-mtn {
  //           margin: 80px !important;        
  //       }
  //   }
  // }





// // .left-sidebar-collapse {
// //   font-size: 1.2rem;
// // }

// %rightSide {
//   width: 95vw;
//   max-width: 380px;
//   right: -95vw;
//   &.active {
//     right: 0;
//   }
// }

// %sidebar {
//   position: fixed;
//   height: 100%;
//   z-index: 99999;
//   background: white;
//   transition: all 0.3s;
//   overflow-y: auto;
//   -webkit-overflow-scrolling: touch;
//   box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
//   top: 0;

//   .dismiss {
//     width: 35px;
//     height: 35px;
//     line-height: 35px;
//     text-align: center;
//     position: absolute;
//     top: 25px;
//     right: 10px;
//     z-index: 10;
//     cursor: pointer;
//     -webkit-transition: all 0.3s;
//     -o-transition: all 0.3s;
//     transition: all 0.3s;
//   }
//   .sidebar-header {
//     background: #fff;
//     z-index: 3;
//     position: relative;
//     .navbar-brand {
//       width: 80%;
//     }
//   }
// }

// .my-friend-sidebar {
//   @extend %rightSide;
//   @extend %sidebar;
//   .sidebar-header {
//     .message-header {
//       padding: 30px 20px;
//     }
//   }
//   .message-list {
//     padding: 10px 20px;
//     .mw-150 {
//       max-width: 160px;
//     }
//     .more-icon {
//       transform: rotate(90deg);
//       cursor: pointer;
//     }
//     .dropdown-menu {
//       text-align: center;
//       border-radius: 13px;
//       box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.16);
//       border: 1px solid rgba(0, 0, 0, 0.08);
//       left: -15px;

//       .dropdown-item {
//         font-size: 16px;
//         color: $secondary;
//         font-weight: 500;
//         &.active {
//           background-color: #f8f9fa;
//         }
//         &:active {
//           background-color: #f8f9fa;
//         }
//       }
//       .border-line {
//         box-shadow: inset 0 -1px 0 0 #e0e4e8;
//         height: 1px;
//       }
//       .message-collapse {
//         cursor: pointer;
//       }
//     }
//   }
// }

// .fixed-sidebar-scroll-landscap {
//   &.active {
//     height: 100%;
//   }
// }
// .overlay {
//   display: none;
//   position: fixed;
//   width: 100vw;
//   height: 100vh;
//   background: rgba(0, 0, 0, 0.7);
//   z-index: 998;
//   opacity: 0;
//   transition: all 0.5s ease-in-out;
//   &.open {
//     display: block;
//     opacity: 1;
//   }
// }
