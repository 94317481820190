%avatar {
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.2);
}
.profile-pic-200 {
  width: 200px;
  height: 200px;
  @extend %avatar;
}
.profile-pic-160 {
  width: 160px;
  height: 160px;
  @extend %avatar;
}
.profile-pic-140 {
  width: 160px;
  height: 160px;
  @extend %avatar;
}

.profile-pic-120 {
  width: 120px;
  height: 120px;
  @extend %avatar;
}
.profile-pic-96 {
  width: 96px;
  height: 96px;
  @extend %avatar;
}
.profile-pic-80 {
  width: 80px;
  height: 80px;
  @extend %avatar;
}
.profile-pic-72 {
  @extend %avatar;
  width: 72px;
  height: 72px;
}
.profile-pic-60 {
  @extend %avatar;
  width: 60px;
  height: 60px;
}

.profile-pic-56 {
  @extend %avatar;
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
}
.profile-pic-48 {
  width: 48px;
  height: 48px;
  @extend %avatar;
}

figure {
  position: relative;
  figcaption {
    &.online-56 {
      position: absolute;
      top: 0px;
      right: 5px;
      width: 11px;
      height: 11px;
      background-image: radial-gradient(circle at 50% 31%, #73ffb3, #50de80);
      box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 1);
      border-radius: 50%;
      border: 2px solid #fff;
    }
    &.online-48 {
      position: absolute;
      top: 5px;
      right: -5px;
      width: 11px;
      height: 11px;
      background-image: radial-gradient(circle at 50% 31%, #73ffb3, #50de80);
      box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 1);
      border-radius: 50%;
      border: 2px solid #fff;
    }
    &.online-72 {
      position: absolute;
      top: 5px;
      left: 40px;
      width: 11px;
      height: 11px;
      background-image: radial-gradient(circle at 50% 31%, #73ffb3, #50de80);
      box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 1);
      border-radius: 50%;
      border: 2px solid #fff;
    }
    &.online-96 {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 15px;
      height: 15px;
      background-image: radial-gradient(circle at 50% 31%, #73ffb3, #50de80);
      box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      border: 2px solid white;
    }
    &.online-120 {
      position: absolute;
      top: 15px;
      right: 0px;
      width: 18px !important;
      height: 18px;
      background-image: radial-gradient(circle at 50% 31%, #73ffb3, #50de80);
      box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      border: 2px solid white;
    }
    &.online-140 {
      position: absolute;
      top: -40px;
      right: 15px;
      width: 18px;
      height: 18px;
      background-image: radial-gradient(circle at 50% 31%, #73ffb3, #50de80);
      box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 1);
      border-radius: 50%;
      border: 2px solid #fff;
    }
    &.online-160 {
      position: absolute;
      top: 10px;
      right: 15px;
      width: 18px;
      height: 18px;
      background-image: radial-gradient(circle at 50% 31%, #73ffb3, #50de80);
      box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 1);
      border-radius: 50%;
      border: 2px solid #fff;
    }
  }
}


.quick-figure {
  figure {
    position: relative;
    figcaption {
      &.online-56 {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 11px;
        height: 11px;
        min-height: 11px !important;
        background-image: radial-gradient(circle at 50% 31%, #73ffb3, #50de80);
        box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 1);
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
  }
}

.sidebar-header {
  figure {
    figcaption {
      &.online-120 {
        top: 5px;
        left: 194px;
        border-color: $miggsterBGDarkGrey;
      }
    }
  }
}
.enter-raffles {
  figure {
    position: relative;
    figcaption {
      &.online-56 {
        position: absolute;
        top: 5px;
        right: 60px;
        width: 11px;
        height: 11px;
        min-height: 11px !important;
        background-image: radial-gradient(circle at 50% 31%, #73ffb3, #50de80);
        box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 1);
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
  }
}


.message-header{
  figure {
    figcaption {
      &.online-48 {
        top: -15px!important;
      }
    }
  }

}

@include media-breakpoint-down(lg) {

  figure {
    figcaption {
      &.online-140 {
        left: 125px;
      }
      &.online-160 {
        right: -5px;
      }
    }
  }

.sidebar-header {
  figure {
    figcaption {
      &.online-120 {
        left: 200px;
      }
    }
  }
}


.message-header{
  figure {
    figcaption {
      &.online-48 {
        top: -20px!important;
      }
    }
  }

}

}

@include media-breakpoint-down(md) {
}

@include media-breakpoint-down(sm) {
  figure {
    figcaption {
      &.online-72 {
        left: 30px;
      }
      &.online-120 {
        right: 115px;
      }
      &.online-140 {
        left: 50px;
        top: -40px;
        width: 15px;
        height: 15px;
      }
      &.online-160 {
        left: 195px;
      }
    }
  }
  .swiper-slide {
    figure {
      figcaption {
        &.online-120 {
          right: 0px;
        }
      }
    }
  }
  .followBox {
    figure {
      figcaption {
        &.online-120 {
          right: 0px;
        }
      }
    }
  }

  .popularBox {
    figure {
      figcaption {
        &.online-120 {
          right: 0px;
        }
      }
    }
  }


  .sidebar-header {
    figure {
      figcaption {
        &.online-120 {
          left: 170px;
        }
      }
    }
  }
  
  .playerBox{
    figure {
      figcaption {
        &.online-120 {
          right: 0px;
        }
      }
    }
  }


  .profile-pic-72 {
    width: 50px;
    height: 50px;
  }
  .profile-pic-120 {
    width: 80px;
    height: 80px;
  }
  .profile-pic-140 {
    width: 65px;
    height: 65px;
  }
  .profile-pic-96 {
    width: 50px;
    height: 50px;
  }
  .profile-pic-56 {
    width: 40px;
    height: 40px;
  }
}
@include media-breakpoint-down(xs) {
  
}

@media only screen and (max-device-width: 320px) {

  .sidebar-header {
    figure {
      figcaption {
        &.online-120 {
          left:155px;
        }
      }
    }
  }

  .profileBox{
  figure{
    figcaption{
      &.online-160 {
        left:170px;
      }
    }
  }
}

}

@include media-breakpoint-up(lg) {

  figure {
    figcaption {
      &.online-48 {
        top: 12px;
        right: -5px;
      }
    }
  }
}